<template>
  <div class="dashboard-footer">
    <el-row>
      <!-- <el-col :xs="24" :sm="{span: 12, offset: 6}">
        <a href="http://beian.miit.gov.cn" target="_blank">苏ICP备2020055414号</a>
      </el-col> -->
      <el-col :xs="24">
        <a>© 2021  缩吧短网址 版权所有</a>
      </el-col>
    </el-row>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DashboardFootyaer'
})
</script>

<style lang="scss" scoped>
  :deep(.el-footer) {
    padding: 0;
  }
  .dashboard-footer {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 4px 0;
    background: rgb(37, 37, 37);
  }
  :deep(.el-row) {
    height: 100%;
    display: flex;
    align-items: center;
  }
  a {
    color: #FFF;
    opacity: .5;
    font-size: .825rem;
    font-weight: 500;
    text-decoration: none;
  }
  :deep(.el-col) {
    text-align: center;
  }
</style>
